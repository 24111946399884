(function () {
  'use strict';

  class Transactions {
    constructor(TransactionsRest, $cookies, $rootScope) {
      this.TransactionsRest = TransactionsRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    transactionsIndex(transactions = {}, callback = angular.noop) {
      return this.TransactionsRest.transactions().get(transactions,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    transactionsEdit(transactions = {}, callback = angular.noop) {
      return this.TransactionsRest.transactions().update(transactions,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Transactions
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Transactions', Transactions);
}());
